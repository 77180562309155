import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from '../../components/Carousel'
import { GatsbyImage } from "gatsby-plugin-image"
import Slide from '../../components/Carousel/Slide'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    h1: {
        fontSize: '3.125rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: -1,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.875rem',
        },
    },
    h2: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
        },
    },
    body1: {
        fontFamily: 'IBM Plex Sans',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: 1.6,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    body2: {
        fontFamily: 'IBM Plex Sans',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: 1.6,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    'zoom': {
      position: 'absolute',
      backgroundColor: '#eeeeeecc',
      flexDirection: 'column',
      zIndex: 20000,
      top: 64,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      margin: 'auto',
    }
}))

const gidMapper = (image) => ({ ...image.gatsbyImageData})

const concatImages = (game) => {
  if (game.image && game.image.gatsbyImageData) {
    return [game.image.gatsbyImageData, ...(game.extraImages || []).map(gidMapper)]
  }
  return []
}

const DEFAULT_WIDTH = 260
const DEFAULT_HEIGHT = 260

export default (props) => {
    const { game, ...sliderProps } = props
    const images = concatImages(game)
    const classes = useStyles()
    const [zoom, setZoom] = useState(false)
    const [imageIndex, setImageIndex] = useState(0)
    const hasTitle = Boolean(props.title)
    const zoomImage = (image) => (event) => {
      setImageIndex(image)
      setZoom(true)
    }

    const closeImage = () => {
      setZoom(false)
    }

    return (
        <Box pt={5} textAlign="center">
            {hasTitle && (
                <Typography className={classes.h1} gutterBottom>
                    {props.title}
                </Typography>
            )}
            <Carousel
                {...sliderProps}
                display={3}
                controlsVisible={false}
                space={240}
                height={props.height || DEFAULT_WIDTH}
                width={props.width || DEFAULT_HEIGHT}
                border={1}
                borderColor="#9b9b9b66"
                slidePerspective={35}
                visibleSlides={3}
                displayableSlides={3}
                infiniteLoop={true}
                animationSpeed={500}
                backgroundColor="#fafafae0"
                boxShadow="5px 5px 9px 0px rgba(0, 0, 0, 0.44)"
                delay={2000}
                direction="rtl"
                pauseOnHover={true}
                interactive={true}
              >
                {images.map((image, index) => {
                    return (
                        <Slide
                          key={index}
                          classes={classes}

                          >
                          <GatsbyImage
                            alt={game.title}        
                            style={{ margin: 0, height: props.height || DEFAULT_HEIGHT, width: props.width || DEFAULT_WIDTH }}
                            image={image}
                            objectFit="contain"
                          />
                          <Avatar style={{position: 'absolute', bottom:16, right:16, zIndex: 20000, backgroundColor: "#dddddda0"}} >
                            <IconButton aria-label="zoom" onClick={zoomImage(index)}>
                              <ZoomInRoundedIcon fontSize="large" color="primary" />
                            </IconButton>
                          </Avatar>
                        </Slide>
                    )
                })}
            </Carousel>
            {zoom && <div className={classes.zoom}>
                          <Avatar style={{position: 'absolute', top:16, right:16, zIndex: 20000, backgroundColor: "#dddddda0"}} >
                            <IconButton aria-label="zoom" onClick={closeImage}>
                              <CloseIcon fontSize="large" color="primary" />
                            </IconButton>
                          </Avatar>

                          <GatsbyImage
                            alt={game.title}        
                            style={{ margin: 0 }}
                            image={images[imageIndex]}
                            objectFit="contain"
                          />
            </div>}
        </Box>
    )
}
