export const getParts = (location) => {
  const { pathname } = location;
  const [, city, resource, id, slug] = pathname.split('/');
  return {
    city,
    resource,
    id,
    slug,
  }
}
