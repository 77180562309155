import React from 'react';
import { graphql } from "gatsby"

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Game from '../components/Game'
import { Spacer } from './allgames';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useLocation } from "@reach/router";
import { getSrc } from "gatsby-plugin-image"

const GamePage = (props) => {
  const { data } = props;

  const game = data.game;
  const {playingTime, maximumPlayers, minimumPlayers, year } = game
  const defaultShortDescription = `Juego de Mesa: ${game.title}. Año ${year}. Duración ${playingTime} min. Juegan ${minimumPlayers} - ${maximumPlayers}`
  const shortDescription = (!game.shortDescription || game.shortDescription.shortDescription === '') ? defaultShortDescription : game.shortDescription.shortDescription;
  const description = (game.description && game.description.raw) ? documentToPlainTextString(JSON.parse(game.description.raw)) : '';


  if (game === null || game === undefined) {
    console.log('gameId', data, props.pageContext)
    console.log('data', data)
  }
  const image = getSrc(game.image) || null;
  const location = useLocation().pathname;
  return (
    <Layout {...props}>
      <SEO 
        {...props.pageContext.seo}
        siteUrl={data.site.siteMetadata.siteUrl}
        thumbnail={props.pageContext.thumbnail}
        seo={{...props.pageContext.seo, image}}
        title={`Juego de mesa: ${game.title}`}
        pathName={props.pageContext.pageUrl}
        description={shortDescription}
        image={image}
        type="game"
      />
      <Spacer />
      <Game game={game} location={location} plainDescription={description} />
    </Layout>
  )
}

export default GamePage;


export const query = graphql`
  query($city: String="ba", $gameId: String!, $expansion: Boolean! = false) {
    seo: contentfulComponentSeo(name: {eq: "games"}, node_locale: {eq: "es-AR"}) {
      title
      description {
        description
      }
      image {
        gatsbyImageData(
          layout: FIXED,
          width: 600,
          height: 600
        )
        id
        title
      }
      name
    }
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    game: contentfulGame(
      contentful_id: {eq: $gameId}, 
      cities: {
        elemMatch: {
          contentful_id: {eq: $city}
        }
      }, 
      node_locale: {eq: "es-AR"}, 
      expansion: {eq: $expansion}
    ) {
      ...GameEntry
    }
     
  }
`

